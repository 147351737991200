import "./style.css";
import * as THREE from "three";
import { WorkClass } from "./data.js";
import * as ZoomConfig from "./zoomConfig.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { SMAAPass } from 'three/examples/jsm/postprocessing/SMAAPass.js'
import firefliesVertexShader from './shaders/fireflies/vertex.glsl'
import firefliesFragmentShader from './shaders/fireflies/fragment.glsl'
import * as dat from "lil-gui";
import Stats from 'three/examples/jsm/libs/stats.module'
import gsap from "gsap";
import { Plane, Raycaster, Vector3 } from "three";
import CANNON from 'cannon'
import CannonDebugger from "cannon-es-debugger";

const gui = new dat.GUI()
gui.hide()
// const stats = Stats()
// document.body.appendChild(stats.dom)

const loadingManager = new THREE.LoadingManager(
    //Loaded
    () => {
        // gsap.to(overlayMaterial.uniforms.uAlpha, {duration: 3, value: 0})
        // console.log("loaded");
        document.getElementById("linguas").classList.remove("d-none");

        // let dNone = setInterval(function () {
        //     document.getElementById("loading").classList.add("d-none");
        // }, 4200)
    },
    //Progress
    (itemURL, itemsLoaded, itemsTotal) => {
        // console.log(itemsLoaded / itemsTotal);


    }

)



//Particles
let group;
let container;
const particlesData = [];
let positions, colors;
let particles;
let pointCloud;
let particlePositions;
let linesMesh;

const maxParticleCount = 1000;
let particleCount = 100;
const r = 400;
const rHalf = r / 2;

const effectController = {
    showDots: true,
    showLines: true,
    minDistance: 80,
    limitConnections: false,
    maxConnections: 10,
    particleCount: 100
};


// Debug

const debugObject = {};
const cubeTextureLoader = new THREE.CubeTextureLoader();
const textureLoader = new THREE.TextureLoader(loadingManager);

// Canvas
const canvas = document.querySelector("canvas.webgl");

// Textures
//TV Sprite
// const TVMap = textureLoader.load("textures/TVText.png")
const backgroundFloor = textureLoader.load("textures/bg-32x32.png");
const bakeTVFloor = textureLoader.load("textures/work/base_intro.png")
const bakeWorkFloor = textureLoader.load("textures/work/basework.png")
const bakeWebARFloor = textureLoader.load("textures/work/base_webar.png")
const bakeEcommerceFloor = textureLoader.load("textures/work/base_ecommerce.png")
const bakeFacebookFloor = textureLoader.load("textures/work/base_fbAds.png")
const shadowGlobal = textureLoader.load("textures/sombra-chao.png")
const toldoBake = textureLoader.load("textures/work/BakeToldo.png")
const notebookBake = textureLoader.load("textures/work/BakeNotebook.png")

const socialEmojis = ["textures/lovesphere.png", "textures/likesphere.png"]

const ptUm = textureLoader.load("textures/textoTVpt1.png")
const ptDois = textureLoader.load("textures/textoTVpt2.png")

const enUm = textureLoader.load("textures/textoTVen1.png")
const enDois = textureLoader.load("textures/textoTVen2.png")

const esUm = textureLoader.load("textures/textoTVes1.png")
const esDois = textureLoader.load("textures/textoTVes2.png")

bakeTVFloor.generateMipmaps = false;
bakeWorkFloor.generateMipmaps = false;
bakeWebARFloor.generateMipmaps = false;
bakeEcommerceFloor.generateMipmaps = false;
bakeFacebookFloor.generateMipmaps = false;
// TVMap.generateMipmaps = false;
toldoBake.generateMipmaps = false;
notebookBake.generateMipmaps = false;

bakeTVFloor.magFilter = THREE.NearestFilter;
bakeWorkFloor.magFilter = THREE.NearestFilter;
bakeWebARFloor.magFilter = THREE.NearestFilter;
bakeEcommerceFloor.magFilter = THREE.NearestFilter;
bakeFacebookFloor.magFilter = THREE.NearestFilter;
toldoBake.magFilter = THREE.NearestFilter;
toldoBake.magFilter = THREE.NearestFilter;
notebookBake.encoding = THREE.sRGBEncoding;
toldoBake.encoding = THREE.sRGBEncoding;

bakeTVFloor.encoding = THREE.sRGBEncoding;
bakeWorkFloor.encoding = THREE.sRGBEncoding;
bakeWebARFloor.encoding = THREE.sRGBEncoding;
bakeEcommerceFloor.encoding = THREE.sRGBEncoding;
bakeFacebookFloor.encoding = THREE.sRGBEncoding;
backgroundFloor.magFilter = THREE.LinearFilter;

// TVMap.wrapS = THREE.MirroredRepeatWrapping;
// TVMap.repeat.set(1, 1);

// Scene
const scene = new THREE.Scene();
scene.background = backgroundFloor;
scene.background.encoding = THREE.sRGBEncoding;

/**
 * Overlay
 */


group = new THREE.Group();
group.scale.set(0.0008, 0.0008, 0.0008)
group.position.set(0., 1.5, -0.5)
group.rotation.y = Math.PI * 0.24

// gui.add(group.position, "x").min(-2).max(2).step(0.01);
// gui.add(group.position, "y").min(-2).max(2).step(0.01);
// gui.add(group.position, "z").min(-2).max(2).step(0.01);

scene.add(group);

// gsap.to(group.position, {
//     duration: 0.8,
//     ease: gsap.Sine,
//     y:1.57,
//     yoyo: true,
//     repeat: -1,
//     })

// gsap.to(group.rotation, {
//     duration: 14,
//     ease: 'power0.inOut',
//     y:3.14*2,
//     x:3.14*2,
//     yoyo: false,
//     repeat: -1})

const colorHelper = new THREE.Color(0xffffff).multiplyScalar(15)
const corDaLinha = new THREE.Color(0x00D2FF).multiplyScalar(30)
const corDoPonto = new THREE.Color(0x00D2FF).multiplyScalar(30)

const helper = new THREE.BoxHelper(new THREE.Mesh(new THREE.BoxGeometry(r, r, r)));
helper.material.color = colorHelper;
// helper.material.blending = THREE.AdditiveBlending;
helper.material.transparent = false;
// group.add( helper );

const segments = maxParticleCount * maxParticleCount;

positions = new Float32Array(segments * 3);
colors = new Float32Array(segments * 3);


const pMaterial = new THREE.PointsMaterial({
    color: corDoPonto,
    size: 0.04,
    blending: THREE.AdditiveBlending,
    transparent: true,
    sizeAttenuation: true
});

particles = new THREE.BufferGeometry();
particlePositions = new Float32Array(maxParticleCount * 3);

for (let i = 0; i < maxParticleCount; i++) {

    const x = Math.random() * r - r / 2;
    const y = Math.random() * r - r / 2;
    const z = Math.random() * r - r / 2;

    particlePositions[i * 3] = x;
    particlePositions[i * 3 + 1] = y;
    particlePositions[i * 3 + 2] = z;

    // add it to the geometry
    particlesData.push({
        velocity: new THREE.Vector3(- 1 + Math.random() * 2, - 1 + Math.random() * 2, - 1 + Math.random() * 2),
        numConnections: 0
    });

}

particles.setDrawRange(0, particleCount);
particles.setAttribute('position', new THREE.BufferAttribute(particlePositions, 3).setUsage(THREE.DynamicDrawUsage));

// create the particle system
pointCloud = new THREE.Points(particles, pMaterial);
group.add(pointCloud);

const geometry = new THREE.BufferGeometry();

geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3).setUsage(THREE.DynamicDrawUsage));
geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3).setUsage(THREE.DynamicDrawUsage));

geometry.computeBoundingSphere();

geometry.setDrawRange(0, 0);
const material = new THREE.LineBasicMaterial({
    vertexColors: true,
    color: corDaLinha,
    // blending: THREE.AdditiveBlending,
    // transparent: true
});

linesMesh = new THREE.LineSegments(geometry, material);
group.add(linesMesh);

// scene.fog = new THREE.Fog( 0xFFFFFF, 10, 45);

/**
 * Update all materials
 */
const updateAllMaterials = () => {
    scene.traverse((child) => {
        if (
            child instanceof THREE.Mesh &&
            child.material instanceof THREE.MeshLambertMaterial
        ) {
            child.material.envMap = environmentMap;
            child.material.envMapIntensity = debugObject.envMapIntensity;
            // child.castShadow = true;
            // child.receiveShadow = true;
        }
    });
};

/**
 * Environment map
 */
const environmentMap = cubeTextureLoader.load([
    "/environmentMaps/0/px.jpg",
    "/environmentMaps/0/nx.jpg",
    "/environmentMaps/0/py.jpg",
    "/environmentMaps/0/ny.jpg",
    "/environmentMaps/0/pz.jpg",
    "/environmentMaps/0/nz.jpg",
]);

environmentMap.encoding = THREE.sRGBEncoding;
scene.environment = environmentMap;
debugObject.envMapIntensity = 1;

/**
 * Models
 */

const dracoLoader = new DRACOLoader(loadingManager);
dracoLoader.setDecoderPath("/draco/");
const gltfLoader = new GLTFLoader(loadingManager);
gltfLoader.setDRACOLoader(dracoLoader);



/**
 * RayCaster
 */
const raycaster = new THREE.Raycaster();
const mouse = new THREE.Vector2();
document.addEventListener('mousemove', onPointerMove);


const disableHome = document.getElementById('textohome');
const disableSocial = document.getElementById('textosocial');
const disableWebAR = document.getElementById('textowebar');
const disableECommerce = document.getElementById('textoecommerce');
const disableAds = document.getElementById('textoads');
const loadingBlock = document.getElementById('loading');

disableHome.addEventListener('mouseenter', () => (raycaster.layers.disableAll()));
disableHome.addEventListener('mouseleave', () => (raycaster.layers.enableAll()));

disableSocial.addEventListener('mouseenter', () => (raycaster.layers.disableAll()));
disableSocial.addEventListener('mouseleave', () => (raycaster.layers.enableAll()));

disableWebAR.addEventListener('mouseenter', () => (raycaster.layers.disableAll()));
disableWebAR.addEventListener('mouseleave', () => (raycaster.layers.enableAll()));

disableECommerce.addEventListener('mouseenter', () => (raycaster.layers.disableAll()));
disableECommerce.addEventListener('mouseleave', () => (raycaster.layers.enableAll()));

disableAds.addEventListener('mouseenter', () => (raycaster.layers.disableAll()));
disableAds.addEventListener('mouseleave', () => (raycaster.layers.enableAll()));

loadingBlock.addEventListener('mouseenter', () => (raycaster.layers.disableAll()));
loadingBlock.addEventListener('mouseleave', () => (raycaster.layers.enableAll()));

function onPointerMove(event) {
    mouse.x = (event.clientX / sizes.width) * 2 - 1;
    mouse.y = -(event.clientY / sizes.height) * 2 + 1;
    cursor.x = event.clientX / sizes.width - 0.5
    cursor.y = - (event.clientY / sizes.height - 0.5)
};
// Cursor
const cursor = {
    x: 0,
    y: 0
}
// window.addEventListener('mousemove', (event) =>
// {
//     // cursor.x = event.clientX / sizes.width - 0.5
//     // cursor.y = - (event.clientY / sizes.height - 0.5)
//     console.log(camera.position)

// })

/**
 * Fireflies
 */

// Material
const firefliesMaterial = new THREE.ShaderMaterial({
    uniforms:
    {
        uTime: { value: 0 },
        uPixelRatio: { value: Math.min(window.devicePixelRatio, 2) },
        uSize: { value: 100 }
    },
    vertexShader: firefliesVertexShader,
    fragmentShader: firefliesFragmentShader,
    transparent: true,
    blending: THREE.AdditiveBlending,
    depthWrite: false,
})



const globalFloorGeo = new THREE.PlaneGeometry(11, 11, 11)
const globalFloorMat = new THREE.MeshBasicMaterial()
globalFloorMat.map = shadowGlobal
globalFloorMat.transparent = true
globalFloorMat.blending = THREE.MultiplyBlending
const globalFloor = new THREE.Mesh(globalFloorGeo, globalFloorMat)
globalFloor.rotation.x = -1.57
globalFloor.rotation.y = 0
globalFloor.rotation.z = 0.75
globalFloor.position.y = -1
globalFloor.position.x = -0.26
globalFloor.position.z = -0.01
globalFloor.scale.x = 1.1
globalFloor.scale.y = 1.1

// let rotationFloor = new THREE.Vector3()
// gui.add(globalFloor.position, "x").min(-10).max(10).step(0.01);
// gui.add(globalFloor.position, "y").min(-10).max(20).step(0.01);
// gui.add(globalFloor.position, "z").min(-10).max(10).step(0.01);
scene.add(globalFloor)


/**
 * glTF model
 */
let modelWondar;
let mixer = null;
let currentIntersect = null;
const wrWork = new WorkClass();
let currentIntersectLinks = null
let currentIntersectButtons = null

gltfLoader.load("/models/cenaBaked.glb", (model) => {
    modelWondar = model.scene;
    modelWondar.scale.set(1, 1, 1);
    modelWondar.position.set(-0.25, -1, 0);
    modelWondar.rotation.set(0, 0.75, 0);
    modelWondar.castShadow = false;
    wrWork.setModel(modelWondar);
    scene.add(modelWondar);
    // console.log(modelWondar)

    modelWondar.getObjectByName("base_work").material.map = bakeWorkFloor
    modelWondar.getObjectByName("base_work").material.map.flipY = false
    modelWondar.getObjectByName("base_facebook_ads").material.map = bakeFacebookFloor
    modelWondar.getObjectByName("base_facebook_ads").material.map.flipY = false
    modelWondar.getObjectByName("base_ecommerce").material.map = bakeEcommerceFloor
    modelWondar.getObjectByName("base_ecommerce").material.map.flipY = false
    modelWondar.getObjectByName("base_webar").material.map = bakeWebARFloor
    modelWondar.getObjectByName("base_webar").material.map.flipY = false
    modelWondar.getObjectByName("base_intro").material.map = bakeTVFloor
    modelWondar.getObjectByName("base_intro").material.map.flipY = false
    modelWondar.getObjectByName("tela_brilho_celular").material.transparent = true
    modelWondar.getObjectByName("tela_brilho_celular").material.blending = THREE.AdditiveBlending
    modelWondar.getObjectByName("instagram").material.toneMapped = false
    modelWondar.getObjectByName("tiktok").material.toneMapped = false
    modelWondar.getObjectByName("snap").material.toneMapped = false
    modelWondar.getObjectByName("tiktokFloor").material.toneMapped = false
    modelWondar.getObjectByName("instagramFloor").material.toneMapped = false
    modelWondar.getObjectByName("linkedinFloor").material.toneMapped = false
    modelWondar.getObjectByName("infoFloor").material.toneMapped =  false
    modelWondar.getObjectByName("tiktokFloor").material.emissive = new THREE.Color(0x00FFF5).multiplyScalar(0.5)
    modelWondar.getObjectByName("instagramFloor").material.emissive = new THREE.Color(0xE700D9).multiplyScalar(0.5)
    modelWondar.getObjectByName("linkedinFloor").material.emissive = new THREE.Color(0x5996E7).multiplyScalar(0.5)
    modelWondar.getObjectByName("infoFloor").material.emissive = new THREE.Color(0x837AE7).multiplyScalar(0.5)
    // modelWondar.getObjectByName("textoTv1").material.emissive = new THREE.Color(0xFFFFFF).multiplyScalar(0.5)
    // modelWondar.getObjectByName("textoTv2").material.emissive = new THREE.Color(0xFFFFFF).multiplyScalar(0.9)
    // modelWondar.getObjectByName("tvtela").material.toneMapped = false
    modelWondar.getObjectByName("cubo").material.color = new THREE.Color(0x000000)
    modelWondar.getObjectByName("cubo").material.metallic = 1.0
    modelWondar.getObjectByName("cubo").material.roughness = 0.2
    modelWondar.getObjectByName("toldo").material.map = toldoBake
    modelWondar.getObjectByName("toldo").material.map.flipY = false
    modelWondar.getObjectByName("notebook").material.map = notebookBake
    modelWondar.getObjectByName("notebook").material.map.flipY = false
    modelWondar.getObjectByName("seta").visible = false;

    document.getElementById("portugues").addEventListener("click", () => {
    modelWondar.getObjectByName("textoTv1").material.map = ptUm
    modelWondar.getObjectByName("textoTv2").material.map = ptDois
    modelWondar.getObjectByName("textoTv1").material.map.flipY = false
    modelWondar.getObjectByName("textoTv2").material.map.flipY = false
    })

   document.getElementById("espanhol").addEventListener("click", () => {
    modelWondar.getObjectByName("textoTv1").material.map = esUm
    modelWondar.getObjectByName("textoTv2").material.map = esDois
    modelWondar.getObjectByName("textoTv1").material.map.flipY = false
    modelWondar.getObjectByName("textoTv2").material.map.flipY = false

    })

    document.getElementById("ingles").addEventListener("click", () => {
    modelWondar.getObjectByName("textoTv1").material.map = enUm
    modelWondar.getObjectByName("textoTv2").material.map = enDois
    modelWondar.getObjectByName("textoTv1").material.map.flipY = false
    modelWondar.getObjectByName("textoTv2").material.map.flipY = false
    })


    gsap.to(modelWondar.getObjectByName("cubo").rotation, {
        duration: 14,
        ease: 'power0.inOut',
        y: 3.14 * 2,
        x: 3.14 * 2,
        yoyo: false,
        repeat: -1
    })


    //Animation
    mixer = new THREE.AnimationMixer(modelWondar)
    const action = mixer.clipAction(model.animations[0])
    const action1 = mixer.clipAction(model.animations[1])
    const action2 = mixer.clipAction(model.animations[2])
    const action3 = mixer.clipAction(model.animations[3])
    const action4 = mixer.clipAction(model.animations[4])
    const action5 = mixer.clipAction(model.animations[5])
    const action6 = mixer.clipAction(model.animations[6])
    action.play()
    action.setLoop(THREE.LoopRepeat)
    action1.play()
    action1.setLoop(THREE.LoopRepeat)
    action2.play()
    action2.setLoop(THREE.LoopPingPong)
    action3.play()
    action3.setLoop(THREE.LoopPingPong)
    action4.play()
    action4.setLoop(THREE.LoopPingPong)
    action5.play()
    action5.setLoop(THREE.LoopPingPong)
    action6.play()
    action6.setLoop(THREE.LoopPingPong)


    updateAllMaterials();

    /**
     * Click Next / Prev Smartphone
     */

    window.addEventListener("click", () => {

        if (currentIntersect) {
            switch (currentIntersect.object) {
                case modelWondar.getObjectByName("seta_next"):
                    // console.log("click Next Smartphone");
                    wrWork.nextItem();
                    break;

                case modelWondar.getObjectByName("seta_prev"):
                    // console.log("click Prev Smartphone");
                    wrWork.prevItem();
                    break;

                case modelWondar.getObjectByName("instagram"):
                    // console.log("click instagram");
                    wrWork.goTo("instagram");
                    break;

                case modelWondar.getObjectByName("snap"):
                    // console.log("click snapchat");
                    wrWork.goTo("snapchat");
                    break;

                case modelWondar.getObjectByName("tiktok"):
                    // console.log("click tiktok");
                    wrWork.goTo("tiktok");
                    break;

                case modelWondar.getObjectByName("QR"):
                    // console.log("click QR");
                    wrWork.openCurrentLink();
                    break;
            }
        }

    });

    const rayCastTick = () => {
        raycaster.setFromCamera(mouse, camera);

        const objectsToTest = [
            modelWondar.getObjectByName("QR"),
            modelWondar.getObjectByName("seta_next"),
            modelWondar.getObjectByName("seta_prev"),
            modelWondar.getObjectByName("instagram"),
            modelWondar.getObjectByName("snap"),
            modelWondar.getObjectByName("tiktok"),
            modelWondar.getObjectByName("carta"),
            modelWondar.getObjectByName("tiktokFloor"),
            modelWondar.getObjectByName("instagramFloor"),
            modelWondar.getObjectByName("infoFloor"),
            modelWondar.getObjectByName("linkedinFloor"),

        ];


        const objectsToTestBloom = [
            modelWondar.getObjectByName("QR"),
            modelWondar.getObjectByName("seta_next"),
            modelWondar.getObjectByName("seta_prev"),
            modelWondar.getObjectByName("prateleira"),
            modelWondar.getObjectByName("poltrona001"),
            modelWondar.getObjectByName("bancada_bone"),
            modelWondar.getObjectByName("bancada_oculos"),
            modelWondar.getObjectByName("bancada_batom")
        ];

        const objectsToTestLink = [
            modelWondar.getObjectByName("prateleira"),
            modelWondar.getObjectByName("poltrona001"),
            modelWondar.getObjectByName("carta"),
            modelWondar.getObjectByName("bancada_bone"),
            modelWondar.getObjectByName("bancada_oculos"),
            modelWondar.getObjectByName("bancada_batom"),
            modelWondar.getObjectByName("estante"),
            modelWondar.getObjectByName("instagramFloor"),
            modelWondar.getObjectByName("tiktokFloor"),
            modelWondar.getObjectByName("QRBone"),
            modelWondar.getObjectByName("QROculos"),
            modelWondar.getObjectByName("QRBatom"),
            modelWondar.getObjectByName("QRPrateleira"),
            modelWondar.getObjectByName("QRPoltrona"),
            modelWondar.getObjectByName("linkedinFloor"),
            modelWondar.getObjectByName("infoFloor")
        ];

        const objectsToTestSocial = [
            modelWondar.getObjectByName("bancada_bone"),
            modelWondar.getObjectByName("bancada_batom"),
            modelWondar.getObjectByName("bancada_oculos"),
        ];

        const objectsToTestButton = [
            modelWondar.getObjectByName("botaowebar"),
            modelWondar.getObjectByName("botaosocial"),
            modelWondar.getObjectByName("botaohome"),
            modelWondar.getObjectByName("botaoecommerce"),
            modelWondar.getObjectByName("botaoads"),
        ];

        const intersects = raycaster.intersectObjects(objectsToTest);
        const intersectsBloom = raycaster.intersectObjects(objectsToTestBloom);
        const intersectClick = raycaster.intersectObjects(objectsToTestLink)
        const intersectToTestButton = raycaster.intersectObjects(objectsToTestButton)

        let targetColor = new THREE.Color(0xffffff).multiplyScalar(7)
        let targetColorBancadas = new THREE.Color(0xbae0e9).multiplyScalar(15)
        let targetColorBack = new THREE.Color(0xffffff)

        /**
         * Objects Intersect
         */
        if (intersects.length) {
            if (!currentIntersect) {

            }

            currentIntersect = intersects[0];
        } else {
            if (currentIntersect) {

            }
            currentIntersect = null;
        }
        /**
         * Object Intersects Link
         */
        if (intersectClick.length) {
            if (!currentIntersectLinks) {

            }
            currentIntersectLinks = intersectClick[0];
        } else {
            if (currentIntersectLinks) {
            }
            currentIntersectLinks = null;
        }

        /**
         * Object Intersects button
         */
        if (intersectToTestButton.length) {
            if (!currentIntersectButtons) {

            }
            currentIntersectButtons = intersectToTestButton[0];
        } else {
            if (currentIntersectButtons) {
            }
            currentIntersectButtons = null;
        }


        for (const intersect of intersects) {
            gsap.to(intersect.object.scale, {
                duration: 0.5,
                x: 1.2,
                y: 1.2,
                z: 1.2,
                ease: "power2.out",
            });
        }

        //bancadas
        //Bone
        for (const intersect of raycaster.intersectObject(modelWondar.getObjectByName("bancada_bone"))) {
            gsap.to(modelWondar.getObjectByName("bone").position, {
                duration: 0.5,
                y: 0.05,
                ease: "power3.out",
            })
            gsap.to(modelWondar.getObjectByName("bancada_bone").material.color, {
                duration: 0.5,
                r: targetColorBancadas.r,
                g: targetColorBancadas.g,
                b: targetColorBancadas.b,
            })
        }

        if (!raycaster.intersectObject(modelWondar.getObjectByName("bancada_bone")).find((intersect) => intersect.object === modelWondar.getObjectByName("bone"))) {
            gsap.to(modelWondar.getObjectByName("bone").position, {
                duration: 0.5,
                y: 0.,
                ease: "power3.out",
            })
            gsap.to(modelWondar.getObjectByName("bancada_bone").material.color, {
                duration: 0.5,
                r: targetColorBack.r,
                g: targetColorBack.g,
                b: targetColorBack.b,
            })
        }
        //Batom
        for (const intersect of raycaster.intersectObject(modelWondar.getObjectByName("bancada_batom"))) {
            gsap.to(modelWondar.getObjectByName("batom").position, {
                duration: 0.5,
                y: 0.05,
                ease: "power3.out",
            })
            gsap.to(modelWondar.getObjectByName("bancada_batom").material.color, {
                duration: 0.5,
                r: targetColorBancadas.r,
                g: targetColorBancadas.g,
                b: targetColorBancadas.b,
            })
        }

        if (!raycaster.intersectObject(modelWondar.getObjectByName("bancada_batom")).find((intersect) => intersect.object === modelWondar.getObjectByName("bone"))) {
            gsap.to(modelWondar.getObjectByName("batom").position, {
                duration: 0.5,
                y: 0.,
                ease: "power3.out",
            })
            gsap.to(modelWondar.getObjectByName("bancada_batom").material.color, {
                duration: 0.5,
                r: targetColorBack.r,
                g: targetColorBack.g,
                b: targetColorBack.b,
            })
        }

        //oculos
        for (const intersect of raycaster.intersectObject(modelWondar.getObjectByName("bancada_oculos"))) {
            gsap.to(modelWondar.getObjectByName("oculos").position, {
                duration: 0.5,
                y: 0.05,
                ease: "power3.out",
            })
            gsap.to(modelWondar.getObjectByName("bancada_oculos").material.color, {
                duration: 0.5,
                r: targetColorBancadas.r,
                g: targetColorBancadas.g,
                b: targetColorBancadas.b,
            })
        }

        if (!raycaster.intersectObject(modelWondar.getObjectByName("bancada_oculos")).find((intersect) => intersect.object === modelWondar.getObjectByName("bone"))) {
            gsap.to(modelWondar.getObjectByName("oculos").position, {
                duration: 0.5,
                y: 0.,
                ease: "power3.out",
            })
            gsap.to(modelWondar.getObjectByName("bancada_oculos").material.color, {
                duration: 0.5,
                r: targetColorBack.r,
                g: targetColorBack.g,
                b: targetColorBack.b,
            })
        }


        for (const object of objectsToTest) {
            if (!intersects.find((intersect) => intersect.object === object)) {
                gsap.to(object.scale, {
                    duration: 0.5,
                    x: 1,
                    y: 1,
                    z: 1,
                    ease: "power2.out",
                });
            }
        }

        for (const intersect of intersectsBloom) {
            if (intersectsBloom.find((intersect) => intersect.object)) {
                gsap.to(intersect.object.material.color, {
                    duration: 0.3,
                    r: targetColor.r,
                    g: targetColor.g,
                    b: targetColor.b,
                })
            }
            else {
                gsap.to(intersect.object.material.color, {
                    duration: 0.3,
                    r: targetColorBone.r,
                    g: targetColorBone.g,
                    b: targetColorBone.b,
                });
            }
        }

        for (const intersect of intersectsBloom) {
            gsap.to(intersect.object.material.color, {
                duration: 0.3,
                r: targetColorBack.r,
                g: targetColorBack.g,
                b: targetColorBack.b,
            });
        }
        window.requestAnimationFrame(rayCastTick);
    };
    rayCastTick();
});

const meshes = [];
const bodies = [];

window.addEventListener("click", () => {
    if (currentIntersectLinks) {
        switch (currentIntersectLinks.object) {

            case modelWondar.getObjectByName("prateleira"):
                window.open(
                    "https://webar.wond.ar/estante/",
                    "_blank"
                );
                break;

            case modelWondar.getObjectByName("poltrona001"):
                window.open(
                    "https://webar.wond.ar/poltrona/",
                    "_blank"
                );
                break;

            case modelWondar.getObjectByName("carta"):
                window.open(
                    "mailto:contato@wond.ar"
                );
                break;

            case modelWondar.getObjectByName("bancada_bone"):
                window.open(
                    "https://www.instagram.com/ar/142539467601196/",
                    "_blank"
                );
                break;

            case modelWondar.getObjectByName("bancada_oculos"):
                window.open(
                    "https://www.instagram.com/ar/457146478894515/",
                    "_blank"
                );
                break;

            case modelWondar.getObjectByName("bancada_batom"):
                window.open(
                    "https://www.instagram.com/ar/340582977556720/",
                    "_blank"
                );
                break;

            case modelWondar.getObjectByName("tiktokFloor"):
                window.open(
                    "https://www.tiktok.com/@wondarbr",
                    "_blank"
                );
                break;

            case modelWondar.getObjectByName("instagramFloor"):
                window.open(
                    "https://www.instagram.com/wond.ar/",
                    "_blank"
                );
                break;

            case modelWondar.getObjectByName("QRPoltrona"):
                window.open(
                    "https://webar.wond.ar/poltrona",
                    "_blank"
                );
                break;
            case modelWondar.getObjectByName("QRPrateleira"):
                window.open(
                    "https://webar.wond.ar/estante",
                    "_blank"
                );
                break;
            case modelWondar.getObjectByName("QRBone"):
                window.open(
                    "https://www.instagram.com/ar/142539467601196/",
                    "_blank"
                );
                break;
            case modelWondar.getObjectByName("QROculos"):
                window.open(
                    "https://www.instagram.com/ar/457146478894515/",
                    "_blank"
                );
                break;
            case modelWondar.getObjectByName("QRBatom"):
                window.open(
                    "https://www.instagram.com/ar/340582977556720/",
                    "_blank"
                );
                break;
            case modelWondar.getObjectByName("linkedinFloor"):
                window.open(
                    "https://www.linkedin.com/company/wond-ar/",
                    "_blank"
                );
                break;
            case modelWondar.getObjectByName("infoFloor"):
                document.getElementById("textoinfo").classList.add("abreinfo");
                document.getElementById("fecharinfo").classList.add("posfechar");
                break;
        }
    }
    //Buttons
    if (currentIntersectButtons) {
        let buttonSound = new Audio("./audio/work/botaoazul.mp3");
        switch (currentIntersectButtons.object) {
            case modelWondar.getObjectByName("botaowebar"):
                // console.log("click botaowebar");
                document.getElementById("textowebar").classList.add("entra");
                document.getElementById("textohome").classList.remove("entra");
                document.getElementById("textosocial").classList.remove("entra");
                document.getElementById("textoecommerce").classList.remove("entra");
                document.getElementById("textoads").classList.remove("entra");
                buttonSound.play()
                // raycaster.layers.disableAll()
                gsap.to(modelWondar.getObjectByName("botaowebar").position, {
                    duration: 0.2,
                    y: -0.015,
                    ease: "power3.out",
                    yoyo: true,
                    repeat: 1,
                })
                break;

            case modelWondar.getObjectByName("botaosocial"):
                document.getElementById("textosocial").classList.add("entra");
                document.getElementById("textohome").classList.remove("entra");
                document.getElementById("textowebar").classList.remove("entra");
                document.getElementById("textoecommerce").classList.remove("entra");
                document.getElementById("textoads").classList.remove("entra");
                buttonSound.play()
                // raycaster.layers.disableAll()
                gsap.to(modelWondar.getObjectByName("botaosocial").position, {
                    duration: 0.2,
                    y: -0.015,
                    ease: "power3.out",
                    yoyo: true,
                    repeat: 1,
                })
                break;

            case modelWondar.getObjectByName("botaohome"):
                document.getElementById("textohome").classList.add("entra");
                document.getElementById("textosocial").classList.remove("entra");
                document.getElementById("textowebar").classList.remove("entra");
                document.getElementById("textoecommerce").classList.remove("entra");
                document.getElementById("textoads").classList.remove("entra");
                buttonSound.play()
                // raycaster.layers.disableAll()
                gsap.to(modelWondar.getObjectByName("botaohome").position, {
                    duration: 0.2,
                    y: -0.015,
                    ease: "power3.out",
                    yoyo: true,
                    repeat: 1,
                })
                break;

            case modelWondar.getObjectByName("botaoecommerce"):
                document.getElementById("textoecommerce").classList.add("entra");
                document.getElementById("textohome").classList.remove("entra");
                document.getElementById("textosocial").classList.remove("entra");
                document.getElementById("textowebar").classList.remove("entra");
                document.getElementById("textoads").classList.remove("entra");
                buttonSound.play()
                // raycaster.layers.disableAll()
                gsap.to(modelWondar.getObjectByName("botaoecommerce").position, {
                    duration: 0.2,
                    y: -0.015,
                    ease: "power3.out",
                    yoyo: true,
                    repeat: 1,
                })
                break;

            case modelWondar.getObjectByName("botaoads"):
                document.getElementById("textoads").classList.add("entra");
                document.getElementById("textohome").classList.remove("entra");
                document.getElementById("textosocial").classList.remove("entra");
                document.getElementById("textowebar").classList.remove("entra");
                document.getElementById("textoecommerce").classList.remove("entra");
                buttonSound.play()
                // raycaster.layers.disableAll()
                gsap.to(modelWondar.getObjectByName("botaoads").position, {
                    duration: 0.2,
                    y: -0.015,
                    ease: "power3.out",
                    yoyo: true,
                    repeat: 1,
                })
                break;
        }
    }
});


//Physic World
// let positionSpheres = new THREE.Vector3()
// gui.add(positionSpheres, "x").min(-10).max(10).step(0.01);
// gui.add(positionSpheres, "y").min(-10).max(20).step(0.01);
// gui.add(positionSpheres, "z").min(-10).max(10).step(0.01);



const world = new CANNON.World()
world.broadphase = new CANNON.SAPBroadphase(world)
world.allowSleep = true
world.gravity.set(0, - 9.82, 0)
const planeGeo = new THREE.PlaneGeometry(1, 3)
const planeMat = new THREE.ShadowMaterial({
    color: 0x000000,
    side: THREE.DoubleSide
})
const cannonDebugger = new CannonDebugger(scene, world, {
    // options...
})
const planeMesh = new THREE.Mesh(planeGeo, planeMat);
scene.add(planeMesh)

const defaultMaterial = new CANNON.Material('default')
const defaultContactMaterial = new CANNON.ContactMaterial(
    defaultMaterial,
    defaultMaterial,
    {
        friction: 0.05,
        restitution: 0.8
    }
)
world.addContactMaterial = defaultContactMaterial

const planeBody = new CANNON.Body({
    material: defaultContactMaterial,
    type: CANNON.Body.STATIC,
    shape: new CANNON.Box(new CANNON.Vec3(1., 0.45, 0.002))
});
planeBody.quaternion.setFromEuler(Math.PI / 2, 0, 0.1);
planeBody.position.z = -0.55
planeBody.position.y = 6.13
planeBody.position.x = -0.6
world.addBody(planeBody)

//Sphere

let spawnSpheres = []
let indexSpawn
let spheresAmount = 0

function spawning() {
    for (var i = 0; i < spheresAmount; i++) {

        // const positionSphere = new CANNON.Vec3(positionSpheres.x,  positionSpheres.y, positionSpheres.z);
        const positionSphere = new CANNON.Vec3(-1, 6.28, -0.75);
        const sphereIndex = THREE.Math.randInt(0, socialEmojis.length - 1);
        const sphereTexture = textureLoader.load(socialEmojis[sphereIndex]);
        sphereTexture.encoding = THREE.sRGBEncoding
        const sphereGeo = new THREE.SphereGeometry(0.04, 15, 15);
        const sphereMat = new THREE.MeshStandardMaterial({
            // color: new THREE.Color(0xffea00).multiplyScalar(15),
            metalness: 0.2,
            roughness: 0.5,
            map: sphereTexture
        })
        const sphereMesh = new THREE.Mesh(sphereGeo, sphereMat);
        scene.add(sphereMesh);
        sphereMesh.position.copy(positionSphere)

        const sphereBody = new CANNON.Body({
            material: defaultContactMaterial,
            mass: 0.2,
            shape: new CANNON.Sphere(0.04),
            position: new CANNON.Vec3(positionSphere.x, positionSphere.y, positionSphere.z)
        })
        world.addBody(sphereBody);
        sphereBody.applyForce(new CANNON.Vec3(5 * Math.random() * 5, 20, 5), sphereBody.position)
        meshes.push(sphereMesh);
        bodies.push(sphereBody);
        spawnSpheres.push(sphereMesh)

        let Delete = setInterval(function deleteObjects() {
            scene.remove(sphereMesh)
            world.remove(sphereBody)
        }, 2500)
    }
}

let callSpawn = setInterval(function () {
    spawning()
}, 150)
// let deleteSpawn = setInterval(function(){
//     deleteObjects()
// },500)

//

/**
 * Lights
 */
const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
scene.add(ambientLight);

const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
directionalLight.castShadow = true;
directionalLight.shadow.mapSize.set(1024, 1024);
directionalLight.shadow.camera.far = 15;
directionalLight.shadow.camera.left = -7;
directionalLight.shadow.camera.top = 7;
directionalLight.shadow.camera.right = 7;
directionalLight.shadow.camera.bottom = -7;
directionalLight.position.set(-1, 5, 2.25);
scene.add(directionalLight);


/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight,
};

window.addEventListener("resize", () => {
    // Update sizes
    sizes.width = window.innerWidth;
    sizes.height = window.innerHeight;

    // Update camera
    camera.aspect = sizes.width / sizes.height;
    camera.updateProjectionMatrix();

    // Update renderer
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

    // Update effect composer
    effectComposer.setSize(sizes.width, sizes.height)
    effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
});

const camera = new THREE.PerspectiveCamera(
    20,
    sizes.width / sizes.height,
    0.1,
    150
);




//CameraDebug
gui.add(camera.position, "x").min(-30).max(30).step(0.01);
gui.add(camera.position, "y").min(-30).max(20).step(0.01);
gui.add(camera.position, "z").min(-30).max(30).step(0.01);


scene.add(camera);

/**
 * Animate
 */
const clock = new THREE.Clock();
let previousTime = 0;

let speed = 0.5;


/**
 * Controls
 */
const controls = new OrbitControls(camera, canvas);


//  controls.target.set(10, 6.67, 10)
controls.enabled = true;
controls.screenSpacePanning = false;
controls.enableDamping = true;
// controls.panSpeed = 0.4
// controls.zoomSpeed = 0.25
controls.enableKeys = false;
controls.minPolarAngle = 0;
controls.maxPolarAngle = Math.PI / 2;
controls.minAzimuthAngle = -0.25;
controls.maxAzimuthAngle = 1.5;
controls.target.set(0, 1.5, 0);
controls.enablePan = true;
controls.maxDistance = 30.
// controls.minDistance = 5.

/**
 * Camera Animation
 */
let zoomStep = 0;

//Next
document.getElementById("btverde-hover").addEventListener("click", (e) => {

    document.getElementById("textohome").classList.remove("entra");
    document.getElementById("textosocial").classList.remove("entra");
    document.getElementById("textowebar").classList.remove("entra");
    document.getElementById("textoecommerce").classList.remove("entra");
    document.getElementById("textoads").classList.remove("entra");
    modelWondar.getObjectByName("seta").visible = true;
    let screenSize = ZoomConfig.getScreenSize();
    camera.position.set = ZoomConfig.zoomConfig[zoomStep][screenSize].position;
    if (zoomStep < 5) {
        zoomStep++;
        gsap.to(controls.target, {
            duration: ZoomConfig.timeAnimation,
            x: 0.1,
            ease: ZoomConfig.curveAnimation,
        });
    }
    if (zoomStep >= 0 && zoomStep <= 5) {
        gsap.to(controls.target, ZoomConfig.zoomConfig[zoomStep][screenSize].target);
        gsap.to(camera.position, ZoomConfig.zoomConfig[zoomStep][screenSize].position);
    }
    if (zoomStep >= 5) {
        spheresAmount = 1
        // console.log(spheresAmount)
    } else {
        spheresAmount = 0
        // console.log(spheresAmount)
    }
});
if (zoomStep === 0) {
    controls.rotateSpeed = 0.1;
} else {
    controls.rotateSpeed = 0.1
}

//Prev
let screenSize = ZoomConfig.getScreenSize();
document.getElementById("btvermelho-hover").addEventListener("click", (e) => {

    document.getElementById("textohome").classList.remove("entra");
    document.getElementById("textosocial").classList.remove("entra");
    document.getElementById("textowebar").classList.remove("entra");
    document.getElementById("textoecommerce").classList.remove("entra");
    document.getElementById("textoads").classList.remove("entra");

    if (zoomStep > 0) {
        zoomStep--;
        gsap.to(controls.target, {
            duration: ZoomConfig.timeAnimation,
            x: 0.1,
            ease: ZoomConfig.curveAnimation,
        });
    }
    if (zoomStep >= 0 && zoomStep <= 5) {
        gsap.to(controls.target, ZoomConfig.zoomConfig[zoomStep][screenSize].target);
        gsap.to(camera.position, ZoomConfig.zoomConfig[zoomStep][screenSize].position);
        // gsap.to(controls.minDistance, ZoomConfig.zoomConfig[zoomStep][screenSize].minDistance);
        // gsap.to(controls.maxDistance, ZoomConfig.zoomConfig[zoomStep][screenSize].maxDistance);
    }
    if (zoomStep >= 5) {
        spheresAmount = 1
        // console.log(spheresAmount)
    } else {
        spheresAmount = 0
        // console.log(spheresAmount)
    }
});
//Debug Distance
gui.add(controls, "maxDistance").min(-40).max(40).step(0.01);
gui.add(controls, "minDistance").min(-40).max(40).step(0.01);

camera.position.x = ZoomConfig.zoomConfig[zoomStep][screenSize].position.x
camera.position.y = ZoomConfig.zoomConfig[zoomStep][screenSize].position.y
camera.position.z = ZoomConfig.zoomConfig[zoomStep][screenSize].position.z
// console.log(ZoomConfig.zoomConfig[zoomStep][screenSize].minDistance.x)
// console.log(ZoomConfig.zoomConfig[zoomStep][screenSize].maxDistance.x)
// console.log(ZoomConfig.zoomConfig[zoomStep][screenSize].position)
// console.log(ZoomConfig.zoomConfig[zoomStep][screenSize].maxDistance.x)
// console.log(ZoomConfig.zoomConfig[zoomStep][screenSize].maxDistance.x)
// controls.minDistance = ZoomConfig.zoomConfig[zoomStep][screenSize].minDistance.x;
// controls.maxDistance = ZoomConfig.zoomConfig[zoomStep][screenSize].maxDistance.x;

/**
 * Pan Fix
 */

const minPan = new THREE.Vector3(-1, 0, -1);
const maxPan = new THREE.Vector3(1, 20, 1);
const _v = new THREE.Vector3();

controls.addEventListener("change", function () {
    _v.copy(controls.target);
    controls.target.clamp(minPan, maxPan);
    _v.sub(controls.target);
    camera.position.sub(_v);
});

/**
 * Renderer
 */

const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true,
    powerPreference: "high-performance"
});
renderer.shadowMap.enabled = false;
renderer.shadowMap.type = THREE.PCFSoftShadowMap;
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
renderer.physicallyCorrectLights = false;
renderer.clearDepth()
renderer.setClearColor(0x000000);
renderer.outputEncoding = THREE.sRGBEncoding
// renderer.toneMappingExposure = Math.pow( 0.9, 4.0 ) 
renderer.toneMappingExposure = 3


/**
 * Post processing
 */
const renderTarget = new THREE.WebGLRenderTarget(
    800,
    600,
    {
        type: THREE.HalfFloatType,
        encoding: THREE.sRGBEncoding,
        minFilter: THREE.NearestFilter,
        magFilter: THREE.NearestFilter,
        samples: 2,
        // toneMappingExposure: 3
    }
)


// Render pass
const renderPass = new RenderPass(scene, camera)

// Effect composer
const effectComposer = new EffectComposer(renderer, renderTarget)
effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
effectComposer.setSize(sizes.width, sizes.height)

// Antialias pass
if (renderer.getPixelRatio() === 1 && !renderer.capabilities.isWebGL2) {
    const smaaPass = new SMAAPass()
    effectComposer.addPass(smaaPass)
}

// Unreal Bloom pass
const unrealBloomPass = new UnrealBloomPass(new THREE.Vector2(sizes.width, sizes.height), 1.5, 0.4, 0.85)

unrealBloomPass.strength = 0.4
unrealBloomPass.radius = 1.
unrealBloomPass.threshold = 1.1
// unrealBloomPass.renderToScreen = false
effectComposer.addPass(renderPass)
effectComposer.addPass(unrealBloomPass)

const meshLook = new THREE.Mesh(new THREE.BoxBufferGeometry)


const tick = () => {
    const elapsedTime = clock.getElapsedTime();
    const deltaTime = elapsedTime - previousTime;
    previousTime = elapsedTime;

    // Update controls
    controls.update();

    //Plexus
    let vertexpos = 0;
    let colorpos = 0;
    let numConnected = 0;
    for (let i = 0; i < particleCount; i++)
        particlesData[i].numConnections = 0;

    for (let i = 0; i < particleCount; i++) {

        // get the particle
        const particleData = particlesData[i];

        particlePositions[i * 3] += particleData.velocity.x;
        particlePositions[i * 3 + 1] += particleData.velocity.y;
        particlePositions[i * 3 + 2] += particleData.velocity.z;

        if (particlePositions[i * 3 + 1] < - rHalf || particlePositions[i * 3 + 1] > rHalf)
            particleData.velocity.y = - particleData.velocity.y;

        if (particlePositions[i * 3] < - rHalf || particlePositions[i * 3] > rHalf)
            particleData.velocity.x = - particleData.velocity.x;

        if (particlePositions[i * 3 + 2] < - rHalf || particlePositions[i * 3 + 2] > rHalf)
            particleData.velocity.z = - particleData.velocity.z;

        if (effectController.limitConnections && particleData.numConnections >= effectController.maxConnections)
            continue;

        // Check collision
        for (let j = i + 1; j < particleCount; j++) {

            const particleDataB = particlesData[j];
            if (effectController.limitConnections && particleDataB.numConnections >= effectController.maxConnections)
                continue;

            const dx = particlePositions[i * 3] - particlePositions[j * 3];
            const dy = particlePositions[i * 3 + 1] - particlePositions[j * 3 + 1];
            const dz = particlePositions[i * 3 + 2] - particlePositions[j * 3 + 2];
            const dist = Math.sqrt(dx * dx + dy * dy + dz * dz);

            if (dist < effectController.minDistance) {

                particleData.numConnections++;
                particleDataB.numConnections++;

                const alpha = 1.0 - dist / effectController.minDistance;

                positions[vertexpos++] = particlePositions[i * 3];
                positions[vertexpos++] = particlePositions[i * 3 + 1];
                positions[vertexpos++] = particlePositions[i * 3 + 2];

                positions[vertexpos++] = particlePositions[j * 3];
                positions[vertexpos++] = particlePositions[j * 3 + 1];
                positions[vertexpos++] = particlePositions[j * 3 + 2];

                colors[colorpos++] = alpha;
                colors[colorpos++] = alpha;
                colors[colorpos++] = alpha;

                colors[colorpos++] = alpha;
                colors[colorpos++] = alpha;
                colors[colorpos++] = alpha;

                numConnected++;

            }

        }

    }

    linesMesh.geometry.setDrawRange(0, numConnected * 2);
    linesMesh.geometry.attributes.position.needsUpdate = true;
    linesMesh.geometry.attributes.color.needsUpdate = true;

    pointCloud.geometry.attributes.position.needsUpdate = true;

    // Update camera
    if (zoomStep === 0) {

        camera.position.x += 0. * 0.05
        // camera.position.x += cursor.x * 0.05
    }
    else {
    }

    // Update Animation
    // TVMap.offset.x = elapsedTime * 0.5

    if (mixer) {
        mixer.update(deltaTime)
    }

    //physic

    world.step(1 / 60, deltaTime, 3);

    planeMesh.position.copy(planeBody.position)
    planeMesh.quaternion.copy(planeBody.quaternion)

    for (let i = 0; i < meshes.length; i++) {
        meshes[i].position.copy(bodies[i].position);
        meshes[i].quaternion.copy(bodies[i].quaternion)
    }

    // Render
    effectComposer.render()
    firefliesMaterial.uniforms.uTime.value = elapsedTime
    // stats.update()
    // cannonDebugger.update() // Update the CannonDebugger meshes

    // renderer.render(scene, camera);


    // Call tick again on the next frame
    window.requestAnimationFrame(tick);
};

tick();