const THREE = require("three");

const textureLoader = new THREE.TextureLoader();
function WorkClass() {
  this.currentSocial = "instagram";
  this.currentItem = 0;
  this.modelWondar;

  this.objList = {
    oQr: null,
    oSmartPhone: null,
    oInstagram: null,
    oTikTok: null,
    oSnapchat: null,
  };

  this.data = {
    instagram: {
      active_color: "#e700aa",
      items: [
        {
          titulo: "Space Adventure",
          qrcode_url: "https://www.instagram.com/ar/479880797109601",
          qrcode_img: "./textures/work/QR/qrSpace.jpg",
          smartphone_img: "/textures/work/filtros/space.jpg",
        },
        {
          titulo: "TROVO",
          qrcode_url: "https://www.instagram.com/ar/358907779510043",
          qrcode_img: "./textures/work/QR/qrTrovo.jpg",
          smartphone_img: "./textures/work/filtros/trovo.jpg",
        },
        {
          titulo: "Champions League",
          qrcode_url: "https://www.instagram.com/ar/1096800007571143/",
          qrcode_img: "./textures/work/QR/qrChampions.jpg",
          smartphone_img: "./textures/work/filtros/champions.jpg",
        },
        {
          titulo: "Influencer do Bem",
          qrcode_url: "https://www.instagram.com/ar/725343958905023/",
          qrcode_img: "./textures/work/QR/qrFalcoes.jpg",
          smartphone_img: "./textures/work/filtros/falcoes.jpg",
        },
        {
          titulo: "Batman",
          qrcode_url: "https://www.instagram.com/ar/3125748707699013/",
          qrcode_img: "./textures/work/QR/qrBatman.jpg",
          smartphone_img: "./textures/work/filtros/batman.jpg",
        },
        {
          titulo: "O PAI TA ON",
          qrcode_url: "https://www.instagram.com/ar/319238912554808",
          qrcode_img: "./textures/work/QR/qrNeymar.jpg",
          smartphone_img: "./textures/work/filtros/paitaon.jpg",
        },
        {
          titulo: "TOTVS",
          qrcode_url: "https://www.instagram.com/ar/640614663811834/",
          qrcode_img: "./textures/work/QR/qrTotvs.jpg",
          smartphone_img: "./textures/work/filtros/totvs.jpg",
        },
        {
          titulo: "iFood",
          qrcode_url: "https://www.instagram.com/ar/440711357751997/",
          qrcode_img: "./textures/work/QR/qrIfood.jpg",
          smartphone_img: "./textures/work/filtros/carnapizza.jpg",
        },
        {
          titulo: "SemParar",
          qrcode_url: "https://www.instagram.com/ar/714888132550263/",
          qrcode_img: "./textures/work/QR/qrSemParar.jpg",
          smartphone_img: "./textures/work/filtros/semparar.jpg",
        },
        {
          titulo: "Gol",
          qrcode_url: "https://www.instagram.com/ar/673008296721120/",
          qrcode_img: "./textures/work/QR/qrGol.jpg",
          smartphone_img: "./textures/work/filtros/gol.jpg",
        },
        {
          titulo: "Honda",
          qrcode_url: "https://www.instagram.com/ar/562558342096627/",
          qrcode_img: "./textures/work/QR/qrHonda.jpg",
          smartphone_img: "./textures/work/filtros/honda.png",
        },
        {
          titulo: "Gillete",
          qrcode_url: "https://www.instagram.com/ar/793631115196817/",
          qrcode_img: "./textures/work/QR/qrGillete.jpg",
          smartphone_img: "./textures/work/filtros/gillete.png",
        },

      ],
    },
    tiktok: {
      active_color: "#64ffbc",
      items: [
        {
          titulo: "House of Anitta",
          qrcode_url: "https://vm.tiktok.com/ZMNbVjhpE/",
          qrcode_img: "./textures/work/QR/anitta.jpg",
          smartphone_img: "./textures/work/filtros/anitta.jpg",
        },
        {
          titulo: "Piracanjuba",
          qrcode_url: "https://www.tiktok.com/sticker/Desafio-PiraWhey-1968506",
          qrcode_img: "./textures/work/QR/piracanjuba.jpg",
          smartphone_img: "./textures/work/filtros/piracanjuba.jpg",
        },
        {
          titulo: "Netflix",
          qrcode_url: "https://www.tiktok.com/sticker/A-Escola-do-Bem-e-do-Mal-Netflix-4504390",
          qrcode_img: "./textures/work/QR/qrEscoladobem.jpg",
          smartphone_img: "./textures/work/filtros/netflixescola.png",
        },
        {
          titulo: "GloboPlay",
          qrcode_url: "https://www.tiktok.com/sticker/ToNoGloboplay-5013165",
          qrcode_img: "./textures/work/QR/qrGloboPlay.png",
          smartphone_img: "./textures/work/filtros/globoplay.png",
        },
      ],
    },
    snapchat: {
      active_color: "#e7e700",
      items: [
        {
          titulo: "Thug of War",
          qrcode_url: "https://www.snapchat.com/unlock/?type=SNAPCODE&uuid=0946adae96864b0382ba42b457fb90fa&metadata=01",
          qrcode_img: "./textures/work/QR/thugOfWar.jpg",
          smartphone_img: "./textures/work/filtros/thugofwar.jpg",
        },
        {
          titulo: "ThumsUP",
          qrcode_url: "https://www.snapchat.com/unlock/?type=SNAPCODE&uuid=27162d43aba24616a83014574713fde5&metadata=01",
          qrcode_img: "./textures/work/QR/thumsUp.jpg",
          smartphone_img: "./textures/work/filtros/thumsup.jpg",
        },
        {
          titulo: "Yippee",
          qrcode_url: "https://www.snapchat.com/unlock/?type=SNAPCODE&uuid=d9653158c8db498c8858aadad2291c91&metadata=01",
          qrcode_img: "./textures/work/QR/Yippee.jpg",
          smartphone_img: "./textures/work/filtros/yippe.jpg",
        },
      ],
    },
  };
  
  /**
   * Abre o link do item atual
   */
  this.openCurrentLink = () => {
    window.open(
      this.data[this.currentSocial].items[this.currentItem].qrcode_url,
      "_blank"
    );
  };

  /**
   * Retorna próxima rede social
   * @returns {string}
   */
  this.nextSocial = () => {
    if (this.currentSocial === "instagram") {
      return "tiktok";
    } else if (this.currentSocial === "tiktok") {
      return "snapchat";
    }
    return "instagram";
  };

  /**
   * Retorna próxima rede social
   * @returns {string}
   */
  this.prevSocial = () => {
    if (this.currentSocial === "tiktok") {
      return "instagram";
    } else if (this.currentSocial === "snapchat") {
      return "tiktok";
    }
    return "snapchat";
  };

  /**
   * Avança para próximo slide
   */
  this.nextItem = () => {
    this.currentItem++;
    if (this.currentItem >= this.data[this.currentSocial].items.length) {
      this.currentSocial = this.nextSocial();
      this.currentItem = 0;
    }
    this.triggerButtonSound();
    this.updateSocialContent();
  };

  /**
   * Retorna um slide
   */
  this.prevItem = () => {
    this.currentItem--;
    if (this.currentItem < 0) {
      this.currentSocial = this.prevSocial();
      this.currentItem = this.data[this.currentSocial].items.length - 1;
    }
    this.triggerButtonSound();
    this.updateSocialContent();
  };

  this.goTo = (social) => {
    this.currentSocial = social;
    this.currentItem = 0;
    this.triggerButtonSound();
    this.updateSocialContent();
  };

  /**
   * Atualiza conteúdo da rede social
   */


  this.updateSocialContent = () => {
    //-- Troca textura do QR Code
    this.objList.oQr.material.map = textureLoader.load(
      this.data[this.currentSocial].items[this.currentItem].qrcode_img
    );

    this.objList.oQr.material.map.flipY = false;
    this.objList.oQr.material.map.generateMipmaps = false;
    this.objList.oQr.material.map.magFilter = THREE.LinearFilter;
    this.objList.oQr.material.map.minFilter = THREE.LinearFilter;
    this.objList.oSmartPhone.material.color.setHex("0xffffff");
    this.objList.oSmartPhone.material.map = textureLoader.load(
      this.data[this.currentSocial].items[this.currentItem].smartphone_img
    );
    this.objList.oSmartPhone.material.map.flipY = false;
    this.objList.oSmartPhone.material.map.generateMipmaps = false;
    this.objList.oSmartPhone.material.map.encoding = THREE.sRGBEncoding;

    switch (this.currentSocial) {
      case "instagram":
        this.objList.oTikTok.material.color.setHex("0xffffff");
        this.objList.oSnapchat.material.color.setHex("0xffffff");
        this.objList.oInstagram.material.color = new THREE.Color(
          this.data.instagram.active_color
        ).multiplyScalar(2.);
        break;
      case "tiktok":
        this.objList.oInstagram.material.color.setHex("0xffffff");
        this.objList.oSnapchat.material.color.setHex("0xffffff");
        this.objList.oTikTok.material.color = new THREE.Color(
          this.data.tiktok.active_color
        ).multiplyScalar(1.6);
        break;
      case "snapchat":
        this.objList.oInstagram.material.color.setHex("0xffffff");
        this.objList.oTikTok.material.color.setHex("0xffffff");
        this.objList.oSnapchat.material.color = new THREE.Color(
          this.data.snapchat.active_color
        ).multiplyScalar(1.7);
        break;
    }
  };

  /**
   * Som de clique no botão
   */
  this.triggerButtonSound = () => {
    let audio = new Audio("./audio/work/button.mp3");
    audio.play();
  };

  /**
   * Após cena carregada, carrega cache de objetos e faz tratamentos iniciais
   * @param {*} modelWondar
   */
  this.setModel = (modelWondar) => {
    this.modelWondar = modelWondar;
    this.objList.oQr = this.modelWondar.getObjectByName("QR");
    this.objList.oSmartPhone = this.modelWondar.getObjectByName("telaCelular");
    this.objList.oInstagram = this.modelWondar.getObjectByName("instagram");
    this.objList.oTikTok = this.modelWondar.getObjectByName("tiktok");
    this.objList.oSnapchat = this.modelWondar.getObjectByName("snap");
    this.updateSocialContent();
  };
}

module.exports = {
  WorkClass,
};
