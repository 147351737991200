let timeAnimation = 1.5;
let curveAnimation = "power2.out";

function getScreenSize() {
  if (window.innerWidth > 767) {
    return "grande";
  } else if (window.innerWidth > 413) {
    return "medio";
  }
  return "pequeno";
}

const zoomConfig = [
  // 0
  {
    pequeno: {
      target: {
        duration: timeAnimation,
        y: 1.5,
        ease: curveAnimation,
      },
      position: {
        duration: timeAnimation,
        x: 5.89,
        y: 15.15,
        z: 18.36,
        ease: curveAnimation,
      },
      maxDistance: {
        x: 25.,
      },
      minDistance: {
        x: 22.26,
      },
    },
    medio: {
      target: {
        duration: timeAnimation,
        y: 1.5,
        ease: curveAnimation,
      },
      position: {
        duration: timeAnimation,
        x: 5.89,
        y: 15.15,
        z: 18.36,
        ease: curveAnimation,
      },
      maxDistance: {
        x: 25.,
      },
      minDistance: {
        x: 22.26,
      },
    },
    grande: {
      target: {
        duration: timeAnimation,
        y: 1.5,
        ease: curveAnimation,
      },
      position: {
        duration: timeAnimation,
        x: 10,
        y: 6.67,
        z: 10,
        ease: curveAnimation,
      },
      maxDistance: {
        x: 15,
      },
      minDistance: {
        x: 2.,
      },
    },
  },
  // 1
  {
    pequeno: {
      target: {
        duration: timeAnimation,
        y: 1.5,
        ease: curveAnimation,
      },
      position: {
        duration: timeAnimation,
        x: 4.45,
        y: 2.84,
        z: 5.20,
        ease: curveAnimation,
      },
      maxDistance: {
        x: 7.,
      },
      minDistance: {
        x: 7.,
      },
    },
    medio: {
      target: {
        duration: timeAnimation,
        y: 1.5,
        ease: curveAnimation,
      },
      position: {
        duration: timeAnimation,
        x: 4.45,
        y: 2.84,
        z: 5.20,
        ease: curveAnimation,
      },
      maxDistance: {
        x: 7.,
      },
      minDistance: {
        x: 7.,
      },
    },
    grande: {
      target: {
        duration: timeAnimation,
        y: 1.6,
        ease: curveAnimation,
      },
      position: {
        duration: timeAnimation,
        x: 2.3,
        y: 2.7,
        z: 2.4,
        ease: curveAnimation,
      },
      maxDistance: {
        x: 15.,
      },
      minDistance: {
        x: 2.,
      },
    },
  },
  // 2
  {
    pequeno: {
      target: {
        duration: timeAnimation,
        y: 2.79,
        ease: curveAnimation,
      },
      position: {
        duration: timeAnimation,
        x: 6.25,
        y: 4.77,
        z: 4.46,
        ease: curveAnimation,
      },
      maxDistance: {
        x: 7.,
      },
      minDistance: {
        x: 7.,
      },
    },
    medio: {
      target: {
        duration: timeAnimation,
        y: 2.79,
        ease: curveAnimation,
      },
      position: {
        duration: timeAnimation,
        x: 6.25,
        y: 4.77,
        z: 4.46,
        ease: curveAnimation,
      },
      maxDistance: {
        x: 7.,
      },
      minDistance: {
        x: 7.,
      },
    },
    grande: {
      target: {
        duration: timeAnimation,
        y: 2.82,
        ease: curveAnimation,
      },
      position: {
        duration: timeAnimation,
        x: 2.1,
        y: 3.7,
        z: 2.1,
        ease: curveAnimation,
      },
      maxDistance: {
        x: 15.,
      },
      minDistance: {
        x: 2.,
      },
    },
  },
  // 3
  {
    pequeno: {
      target: {
        duration: timeAnimation,
        y: 4.0,
        ease: curveAnimation,
      },
      position: {
        duration: timeAnimation,
        x: 4.8,
        y: 6.6,
        z: 4.5,
        ease: curveAnimation,
      },
      maxDistance: {
        x: 7.,
      },
      minDistance: {
        x: 7.,
      },
    },
    medio: {
      target: {
        duration: timeAnimation,
        y: 4.0,
        ease: curveAnimation,
      },
      position: {
        duration: timeAnimation,
        x: 4.8,
        y: 6.6,
        z: 4.5,
        ease: curveAnimation,
      },
      maxDistance: {
        x: 7.,
      },
      minDistance: {
        x: 7.,
      },
    },
    grande: {
      target: {
        duration: timeAnimation,
        y: 4.05,
        ease: curveAnimation,
      },
      position: {
        duration: timeAnimation,
        x: 2.1,
        y: 5.1,
        z: 2.2,
        ease: curveAnimation,
      },
      maxDistance: {
        x: 15.,
      },
      minDistance: {
        x: 2.,
      },
    },
  },
  // 4
  {
    pequeno: {
      target: {
        duration: timeAnimation,
        y: 5.25,
        ease: curveAnimation,
      },
      position: {
        duration: timeAnimation,
        x: 5.,
        y: 7.6,
        z: 5.3,
        ease: curveAnimation,
      },
      maxDistance: {
        x: 7.,
      },
      minDistance: {
        x: 7.,
      },
    },
    medio: {
      target: {
        duration: timeAnimation,
        y: 5.25,
        ease: curveAnimation,
      },
      position: {
        duration: timeAnimation,
        x: 5.,
        y: 7.6,
        z: 5.3,
        ease: curveAnimation,
      },
      maxDistance: {
        x: 7.,
      },
      minDistance: {
        x: 7.,
      },
    },
    grande: {
      target: {
        duration: timeAnimation,
        y: 5.3,
        ease: curveAnimation,
      },
      position: {
        duration: timeAnimation,
        x: 2.2,
        y: 6.4,
        z: 2.4,
        ease: curveAnimation,
      },
      maxDistance: {
        x: 15,
      },
      minDistance: {
        x: 2,
      },
    },
  },
  // 5
  {
    pequeno: {
      target: {
        duration: timeAnimation,
        y: 6.5,
        ease: curveAnimation,
      },
      position: {
        duration: timeAnimation,
        x: 3.9,
        y: 10.,
        z: 4.6,
        ease: curveAnimation,
      },
      maxDistance: {
        x: 7,
      },
      minDistance: {
        x: 7,
      },
    },
    medio: {
      target: {
        duration: timeAnimation,
        y: 6.5,
        ease: curveAnimation,
      },
      position: {
        duration: timeAnimation,
        x: 3.9,
        y: 10.,
        z: 4.6,
        ease: curveAnimation,
      },
      maxDistance: {
        x: 7,
      },
      minDistance: {
        x: 7,
      },
    },
    grande: {
      target: {
        duration: timeAnimation,
        y: 6.5,
        ease: curveAnimation,
      },
      position: {
        duration: timeAnimation,
        x: 2.,
        y: 7.2,
        z: 1.8,
        ease: curveAnimation,
      },
      maxDistance: {
        x: 15,
      },
      minDistance: {
        x: 2,
      },
    },
  },
];

module.exports = {
  zoomConfig,
  timeAnimation,
  curveAnimation,
  getScreenSize,
};
